import React from "react";
import {Grid, GridSize, useMediaQuery, useTheme} from "@material-ui/core";

interface Props {
    elements: JSX.Element[]
}

export const GridGroupByTwo: React.FC<Props> = ({elements}) => {
    const theme = useTheme();
    const wideScreen = useMediaQuery(theme.breakpoints.up('md'));
    const sizeofElems = wideScreen ? 2 : 1;

    const groupedElements = elements.reduce((acc, e, index, arr) => {
        if (index % sizeofElems === 0) {
            acc.push(arr.slice(index, index + sizeofElems));
        }
        return acc;
    }, [] as Array<Array<JSX.Element>>)

    return (
        <Grid container spacing={1}>
            {groupedElements.map((group, parentKey) => {
                const gridSize = Math.floor(12 / sizeofElems) as GridSize;
                return (
                        <Grid
                            key={parentKey}
                            container
                            alignItems="stretch"
                            wrap="nowrap"
                            spacing={1}
                            style={{
                            padding: "4px",
                        }}>
                            {group.map((element, childrenKey) => <Grid item xs={gridSize} key={`${parentKey}-${childrenKey}`}>
                                {element}
                            </Grid>)}
                        </Grid>
                );
            })}
        </Grid>
    );
}