import {createTheme} from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        background: {
            default: '#181a1b',
            paper: "#181a1b"
        },
    },
    typography: {
        allVariants: {
            color: "#d8d4cf"
        },
    },
});

export default theme;